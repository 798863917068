body {
  font-family: $sourceSans;
  font-weight: 400;
  background-color: $baseGray;
  background-image: url('../images/ticks.png');
}

h1 {
  font-family: $tangerineFont;
}

h2 {
  font-family: $tangerineFont;
  font-size: 1.4em;
}

p {
  font-size: 0.95em;
  font-weight: 300;
}

strong {
  font-weight: 400;
}

.row {
  background-color: $baseGray;
  width: 90%;
}

.clearer {
  clear: both;
}

ul.price-list {
  margin: 0;
  padding: 0;
  clear: both;

  li {
    display: block;
    float: left;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);

    span.price-item {
      float: left;
      display: block;
      width: 65%;
      font-weight: 400;
    }

    span.price {
      float: right;
      display: block;
      width: 30%;
      font-weight: 300;
    }
  }
}

/* Header */

div.header-placeholder {
  height: 0;
  display: none;
}

header {
  .columns {
    padding: 0;
    margin: 0;
  }

  div.header-container {
    width: 100%;
    margin: 10px auto;

    div.logo {
      position: relative;
      margin: 0 auto 0 auto;
      width: 250px;

      div.logo-circle {
        margin-left: -15px;
        position: relative;
        float: left;
        border-radius: 50%;
        width: map-get($logoCircleSize, small);
        height: map-get($logoCircleSize, small);
        background-color: $red;
        border: 3px solid white;
        text-align: center;
        font-family: $tangerineFont;
        font-size: 1.3em;

        .logo-circle-inner {
          display: table-cell;
          height: map-get($logoCircleSize, small);
          width: map-get($logoCircleSize, small);
          vertical-align: middle;

          span {
            color: $black;
            margin: 0;
            padding: 0.3em 0 0.2em 0;
            border-top: 1px solid rgba($black, 0.3);
            border-bottom: 1px solid rgba($black, 0.3);
            line-height: 1em;
          }
        }
      }

      div.logo-tree {
        background-color: $red;
        margin-left: 0;
        z-index: 13;

        img {
          width: 100%;
          height: 100%;
          margin-bottom: 10px;
        }
      }

      div.logo-word-apple {
        background-color: $yellow;
        z-index: 12;
      }

      div.logo-word-tree {
        background-color: $green;
        z-index: 11;
      }

      div.logo-word-flowers {
        background-color: $blue;
        z-index: 10;
      }
    }
  }

  div.menu {
    position: relative;
    float: left;
    background-color: $baseGray;
    width: 100%;
    text-align: center;
    margin: 0 0 30px 0;
    border-bottom: 8px solid map-get($sectionColours, welcome);
    border-top: 8px solid map-get($sectionColours, welcome);

    div.small-logo {
      display: none;
    }

    ul.main-menu {
      position: relative;
      list-style-type: none;
      margin: 0 auto;
      padding: 0;
      text-transform: uppercase;
      font-family: $sourceSans;
      font-weight: 300;

      li {
        font-size: 0.6em;
        line-height: 1.2em;
        letter-spacing: 0.02em;
        display: block;
        position: relative;
        float: left;
        height: 35px;
        margin: 0 0 0 26px;
        padding: 0;
        vertical-align: middle;

        div {
          width: 80px;
          height: 35px;
          display: table-cell;
          vertical-align: middle;
          text-align: center;
        }

        a {
          color: $black;
        }
      }

      li#menu-item-welcome.active,
      li#menu-item-welcome:hover {
        @include menuBackgroundImage(map-get($sectionColours, welcome), 80px);
      }

      li#menu-item-bouquets.active,
      li#menu-item-bouquets:hover {
        @include menuBackgroundImage(map-get($sectionColours, bouquets), 80px);
      }

      li#menu-item-weddings.active,
      li#menu-item-weddings:hover {
        @include menuBackgroundImage(map-get($sectionColours, weddings), 80px);
      }

      li#menu-item-events.active,
      li#menu-item-events:hover {
        @include menuBackgroundImage(map-get($sectionColours, events), 80px);
      }

      li#menu-item-remembrance.active,
      li#menu-item-remembrance:hover {
        @include menuBackgroundImage(map-get($sectionColours, remembrance), 80px);
      }

      li#menu-item-contact.active,
      li#menu-item-contact:hover {
        @include menuBackgroundImage(map-get($sectionColours, contact), 80px);
      }
    }
  }
}

header.fixed {
  position: fixed;
  width: 100%;
  z-index: 10000;

  div.header-container {
    display: none;

    div.site-name {
      display: none;
    }

    div.logo {
      display: none;
    }
  }

  div.menu {
    margin: 10px 0 20px 0;
    background-color: #f0f0f0;
    box-shadow: 0 5px 5px -5px rgba(150, 150, 150, 0.25);

    div.small-logo {
      display: block;
      position: relative;
      float: left;
      width: 50px;
      height: 50px;
      margin: -5px 0 -5px 5px;
      border-radius: 50%;
      background-color: $yellow;
      border: 2px solid white;
    }

    ul.main-menu {
      li {
        font-size: 0.6em;
        line-height: 1.2em;
        letter-spacing: 0.02em;
        height: 35px;

        div {
          width: 80px;
          height: 35px;
        }
      }

      li#menu-item-welcome.active,
      li#menu-item-welcome:hover {
        @include menuBackgroundImage(map-get($sectionColours, welcome), 80px);
      }

      li#menu-item-bouquets.active,
      li#menu-item-bouquets:hover {
        @include menuBackgroundImage(map-get($sectionColours, bouquets), 80px);
      }

      li#menu-item-weddings.active,
      li#menu-item-weddings:hover {
        @include menuBackgroundImage(map-get($sectionColours, weddings), 80px);
      }

      li#menu-item-events.active,
      li#menu-item-events:hover {
        @include menuBackgroundImage(map-get($sectionColours, events), 80px);
      }

      li#menu-item-remembrance.active,
      li#menu-item-remembrance:hover {
        @include menuBackgroundImage(map-get($sectionColours, remembrance), 80px);
      }

      li#menu-item-contact.active,
      li#menu-item-contact:hover {
        @include menuBackgroundImage(map-get($sectionColours, contact), 80px);
      }
    }
  }
}

footer {
  .row {
    background-color: $red;
    color: $baseGray;
    padding-top: 20px;
    padding-bottom: 0;

    .columns {
      background-color: transparent;

      .row {
        background-color: transparent;
      }
    }
  }

  .last-footer-row {
    padding-bottom: 50px;
  }
  a {
    @include link($red);
  }
}

div.section,
header,
footer {
  box-shadow: -5px 0 5px -5px rgba(150, 150, 150, 0.25), 5px 0 5px -5px rgba(150, 150, 150, 0.25);
}

// Welcome section
div.section.welcome {
  @include sectionBorder(map-get($sectionColours, welcome));

  div.section-label {
    @include sectionLabel('small', map-get($sectionColours, welcome), map-get($sectionTextColours, welcome));
  }

  div.section-ring {
    display: none;
  }

  div.section-text-circle {
    @include textInElasticBox(map-get($sectionColours, welcome), map-get($sectionTextColours, welcome), 180px);
  }
}

// Bouquets & Arrangements section
div.section.bouquets {
  @include sectionBorder(map-get($sectionColours, bouquets));

  padding-bottom: 30px;

  div.section-label {
    @include sectionLabel('small', map-get($sectionColours, bouquets), map-get($sectionTextColours, bouquets), 25);
  }

  div.section-text-circle {
    @include textInElasticBox(map-get($sectionColours, bouquets), map-get($sectionTextColours, bouquets), 200px);
  }

  div.section-text-circle-2 {
    @include textInCircle(120px, 450px, 520px, map-get($sectionColours, bouquets), map-get($sectionTextColours, bouquets));

    position: static;
    margin: 30px auto 0 auto;

    .section-text {
      font-size: 1em;
      padding: 24px 15px 0 15px;
    }
  }

  div.section-text-circle-3 {
    @include textInCircle(200px, 450px, 40px, map-get($sectionColours, bouquets), map-get($sectionTextColours, bouquets));

    position: static;
    margin: 30px auto 0 auto;

    .section-text {
      font-size: 0.9em;
      padding: 40px 20px 0 20px;
    }
  }

  div.section-image {
    display: none;
  }

  div.section-image-small-2 {
    @include imageInCircle(200px, 450px, 280px, map-get($sectionColours, bouquets), 15px);

    position: static;
    margin: 20px auto 0 auto;
  }

  div.section-image-small-3 {
    display: none;
  }
}

// Weddings Section
div.section.weddings {
  @include sectionBorder(map-get($sectionColours, weddings));

  div.section-label {
    @include sectionLabel('small', map-get($sectionColours, weddings), map-get($sectionTextColours, weddings));
  }

  div.section-ring {
    display: none;
  }

  div.section-text-circle {
    @include textInElasticBox(map-get($sectionColours, weddings), map-get($sectionTextColours, weddings), 170px);
  }

  div.section-text-circle-1 {
    @include textInElasticBox(map-get($sectionColours, weddings), map-get($sectionTextColours, weddings), 30px);
  }

  div.section-text-circle-2 {
    @include textInCircle(120px, 450px, 600px, map-get($sectionColours, weddings), map-get($sectionTextColours, weddings));

    position: static;
    margin: 30px auto 30px auto;

    div.section-text {
      font-size: 1em;
      padding: 24px 10px 0 10px;
    }
  }
}

// Events & Occasions Section
div.section.events {
  @include sectionBorder(map-get($sectionColours, events));

  div.section-label {
    @include sectionLabel('large', map-get($sectionColours, events), map-get($sectionTextColours, events));
  }

  div.section-image {
    display: none;
  }

  div.section-text-circle {
    @include textInElasticBox(map-get($sectionColours, events), map-get($sectionTextColours, events), 160px);
  }

  div.section-text-circle-2 {
    @include textInElasticBox(map-get($sectionColours, events), map-get($sectionTextColours, events), 0);
  }
}

// Remembrance section
div.section.remembrance {
  @include sectionBorder(map-get($sectionColours, remembrance));
  padding-bottom: 30px;

  div.section-label {
    @include sectionLabel('small', map-get($sectionColours, remembrance), map-get($sectionTextColours, remembrance), 25)
  }

  div.section-text-circle {
    @include textInElasticBox(map-get($sectionColours, remembrance), map-get($sectionTextColours, remembrance), 190px);
  }
}

// Contact section
div.section.contact {
  @include sectionBorder(map-get($sectionColours, contact));

  padding-bottom: 30px;

  div.section-label {
    @include sectionLabel('small', map-get($sectionColours, contact), map-get($sectionTextColours, contact));
  }

  div.section-ring {
    display: none;
  }

  div.section-text-circle {
    @include textInElasticBox(map-get($sectionColours, contact), map-get($sectionTextColours, contact), 170px);
  }

  div.section-text-circle-1 {
    @include textInElasticBox(map-get($sectionColours, contact), map-get($sectionTextColours, contact), 0);
  }
}
