@media #{$large-up} {
  .row {
    width: 39.375em;
  }

  header {
    div.header-container {
      width: 550px;

      div.logo {
        div.logo-circle {
          width: map-get($logoCircleSize, large);
          height: map-get($logoCircleSize, large);
          font-size: 2.4em;
          border-width: 5px;

          .logo-circle-inner {
            height: map-get($logoCircleSize, large);
            width: map-get($logoCircleSize, large);
          }
        }

        div.logo-tree {
          margin-left: 0;
        }
      }
    }

    div.menu {
      ul.main-menu {
        li {
          font-size: 0.6em;
          height: 35px;
          margin: 0 0 0 15px;

          div {
            height: 35px;
            width: 80px;
          }
        }

        li:first-child {
          margin-left: 30px;
        }

        li#menu-item-welcome.active,
        li#menu-item-welcome:hover {
          @include menuBackgroundImage(map-get($sectionColours, welcome), 80px);
        }

        li#menu-item-bouquets.active,
        li#menu-item-bouquets:hover {
          @include menuBackgroundImage(map-get($sectionColours, bouquets), 80px);
        }

        li#menu-item-weddings.active,
        li#menu-item-weddings:hover {
          @include menuBackgroundImage(map-get($sectionColours, weddings), 80px);
        }

        li#menu-item-events.active,
        li#menu-item-events:hover {
          @include menuBackgroundImage(map-get($sectionColours, events), 80px);
        }

        li#menu-item-remembrance.active,
        li#menu-item-remembrance:hover {
          @include menuBackgroundImage(map-get($sectionColours, remembrance), 80px);
        }

        li#menu-item-contact.active,
        li#menu-item-contact:hover {
          @include menuBackgroundImage(map-get($sectionColours, contact), 80px);
        }
      }
    }
  }

  header.fixed {
    position: fixed;
    width: 100%;
    max-width: 39.375rem;
    left: 50%;
    margin-left: -19.6875rem;

    div.menu {
      div.small-logo {
        display: block;
        position: relative;
        float: left;
        width: 50px;
        height: 50px;
        margin: -8px 0 -8px 5px;
      }

      ul.main-menu {
        li:first-child {
          margin-left: 15px;
        }
      }
    }
  }

  // Welcome section
  div.section.welcome {
    background-image: url('../images/backgrounds/welcome.jpg');
    height: 680px;

    @include sectionBorder(map-get($sectionColours, welcome));

    div.section-label {
      @include sectionLabel('large', map-get($sectionColours, welcome), map-get($sectionTextColours, welcome));
    }

    div.section-ring {
      @include ring(357px, 50px, 210px, map-get($sectionColours, welcome), 10px);
    }

    div.section-text-circle {
      @include textInCircle(357px, 300px, 25px, map-get($sectionColours, welcome), map-get($sectionTextColours, welcome));

      div.section-text {
        font-size: 1.2em;
        padding: 35px 30px 0 30px;
      }
    }
  }

  div.section.bouquets {
    height: 700px;
    background-image: url('../images/backgrounds/bouquets.jpg');

    @include sectionBorder(map-get($sectionColours, bouquets));

    div.section-label {
      @include sectionLabel('large', map-get($sectionColours, bouquets), map-get($sectionTextColours, bouquets), 44);
    }

    div.section-text-circle {
      @include textInCircle(375px, 50px, 200px, map-get($sectionColours, bouquets), map-get($sectionTextColours, bouquets));

      .section-text {
        font-size: 1.1em;
        padding: 50px 40px 0 40px;
      }
    }

    div.section-text-circle-2 {
      @include textInCircle(200px, 450px, 400px, map-get($sectionColours, bouquets), map-get($sectionTextColours, bouquets));

      .section-text {
        font-size: 1.5em;
        padding: 50px 30px 0 30px;
      }
    }

    div.section-text-circle-3 {
      @include textInCircle(190px, 200px, 40px, map-get($sectionColours, bouquets), map-get($sectionTextColours, bouquets));

      .section-text {
        font-size: 0.9em;
        padding: 20px 20px 0 20px;
      }
    }

    div.section-image {
      @include imageInCircle(300px, 350px, 50px, map-get($sectionColours, bouquets));

      margin: 20px 0 0 0;
    }

    div.section-image-small-1 {
      @include imageInCircle(200px, 220px, 40px, map-get($sectionColours, bouquets), 15px);
    }

    div.section-image-small-2 {
      display: none;
    }

    div.section-image-small-3 {
      display: none;
    }
  }

  div.section.weddings {
    height: 738px;
    background-image: url('../images/backgrounds/weddings.jpg');

    div.section-label {
      @include sectionLabel('large', map-get($sectionColours, weddings), map-get($sectionTextColours, weddings));
    }

    div.section-text-circle {
      @include textInCircle(330px, 75px, 200px, map-get($sectionColours, weddings), map-get($sectionTextColours, weddings));

      div.section-text {
        font-size: 1.1em;
        padding: 35px 35px 0 35px;
      }
    }

    div.section-text-circle-1 {
      @include textInCircle(330px, 350px, 200px, map-get($sectionColours, weddings), map-get($sectionTextColours, weddings));

      div.section-text {
        font-size: 1em;
        padding: 30px 40px 0 40px;
      }
    }

    div.section-text-circle-2 {
      @include textInCircle(150px, 300px, 50px, map-get($sectionColours, weddings), map-get($sectionTextColours, weddings));

      div.section-text {
        padding: 40px 30px 0 30px;
      }
    }
  }

  div.section.events {
    height: 700px;

    div.section-label {
      @include sectionLabel('large', map-get($sectionColours, events), map-get($sectionTextColours, events));
    }

    div.section-image {
      @include imageInCircle(300px, 50px, 320px, map-get($sectionColours, events));

      margin: 0;
    }

    div.section-text-circle {
      @include textInCircle(330px, 125px, 25px, map-get($sectionColours, events), map-get($sectionTextColours, events));
    }

    div.section-text-circle-2 {
      @include textInCircle(280px, 350px, 250px, map-get($sectionColours, events), map-get($sectionTextColours, events));

      div.section-text {
        font-size: 1.1em;
        padding: 40px 30px 0 30px;
      }
    }
  }

  div.section.remembrance {
    height: 655px;
    background-image: url('../images/backgrounds/prices.jpg');
    background-position: right top;

    div.section-label {
      @include sectionLabel('large', map-get($sectionColours, remembrance), map-get($sectionTextColours, remembrance), 44)
    }

    div.section-text-circle {
      @include textInCircle(310px, 250px, 150px, map-get($sectionColours, remembrance), map-get($sectionTextColours, remembrance));

      position: relative;
      margin: 0;

      div.section-text {
        font-size: 1.2em;
      }
    }
  }

  div.section.contact {
    height: 657px;
    background-image: url('../images/backgrounds/poppy.jpg');

    div.section-label {
      @include sectionLabel('large', map-get($sectionColours, contact), map-get($sectionTextColours, contact));
    }

    div.section-ring {
      @include ring(300px, 290px, 245px, map-get($sectionColours, contact));
    }

    div.section-text-circle {
      @include textInCircle(300px, 40px, 245px, map-get($sectionColours, contact), map-get($sectionTextColours, contact));

      div.section-text {
        padding: 80px 30px 0 30px;
        font-size: 1.2em;
      }
    }

    div.section-text-circle-1 {
      @include textInCircle(200px, 220px, 50px, map-get($sectionColours, contact), map-get($sectionTextColours, contact));

      div.section-text {
        padding: 40px 20px 0 20px;
        font-size: 1em;
      }
    }
  }
}
