@import "settings";

// Selectively include Zurb Foundation components
@import
  "./vendor/foundation/components/block-grid",
  "./vendor/foundation/components/grid",
  "./vendor/foundation/components/top-bar",
  "./vendor/foundation/components/type";

// include custom styles.
@import "custom.scss";
@import "small.scss";
@import "medium.scss";
@import "large.scss";
@import "xlarge.scss"
