@media #{$medium-up} {
  span.break {
    white-space: pre;
  }

  span.break::after {
    content: '\A';
  }

  header {
    div.header-container {
      width: 300px;
      margin: 10px auto;

      div.logo {
        width: 100%;

        div.logo-circle {
          margin-left: -20px;
          width: map-get($logoCircleSize, medium);
          height: map-get($logoCircleSize, medium);
          font-size: 1.5em;

          .logo-circle-inner {
            height: map-get($logoCircleSize, medium);
            width: map-get($logoCircleSize, medium);
          }
        }

        div.logo-tree {
          margin-left: 0;
        }
      }
    }
  }

  div.section.welcome {
    @include sectionBorder(map-get($sectionColours, welcome));

    padding-bottom: 30px;

    div.section-ring {
      display: none;
    }

    div.section-text-circle {
      @include textInCircle(300px, 50px, 25px, map-get($sectionColours, welcome), map-get($sectionTextColours, welcome));

      position: static;
      margin: 120px auto 0 auto;

      div.section-text {
        font-size: 1em;
        padding: 30px 30px 0 30px;
      }
    }
  }

  div.section.bouquets {
    @include sectionBorder(map-get($sectionColours, bouquets));

    padding-bottom: 30px;

    div.section-text-circle {
      @include textInCircle(280px, auto, auto, map-get($sectionColours, bouquets), map-get($sectionTextColours, bouquets));

      position: relative;
      margin: -20px auto 0 auto;

      .section-text {
        font-size: 0.9em;
        padding: 30px 25px 0 25px;
      }
    }

    div.section-text-circle-2 {
      @include textInCircle(120px, auto, auto, map-get($sectionColours, bouquets), map-get($sectionTextColours, bouquets));

      position: relative;
      float: right;
      margin: -30px auto 0 auto;

      .section-text {
        font-size: 1em;
        padding: 24px 15px 0 15px;
      }
    }

    div.section-text-circle-3 {
      @include textInCircle(170px, auto, auto, map-get($sectionColours, bouquets), map-get($sectionTextColours, bouquets));

      position: relative;
      float: left;
      margin: 5px auto 0 auto;

      .section-text {
        font-size: 0.8em;
        padding: 20px 15px 0 15px;
      }
    }

    div.section-image {
      @include imageInCircle(280px, auto, auto, map-get($sectionColours, bouquets));

      display: block;
      position: relative;
      margin: 150px auto 0 auto;
    }

    div.section-image-small-2 {
      display: none;
    }

    div.section-image-small-3 {
      display: none;
    }
  }

  div.section.weddings {
    padding-bottom: 30px;

    div.section-text-circle {
      @include textInCircle(280px, 0, 0, map-get($sectionColours, weddings), map-get($sectionTextColours, weddings));

      position: static;
      margin: 100px auto 0 auto;

      div.section-text {
        font-size: 0.95em;
        padding: 30px 25px 0 25px;
      }
    }

    div.section-text-circle-1 {
      @include textInCircle(310px, 0, 0, map-get($sectionColours, weddings), map-get($sectionTextColours, weddings));

      position: relative;
      float: left;
      margin: -20px 0 0 0;

      div.section-text {
        font-size: 0.95em;
        padding: 25px 30px 0 30px;
      }
    }

    div.section-text-circle-2 {
      @include textInCircle(160px, 0, 0, map-get($sectionColours, weddings), map-get($sectionTextColours, weddings));

      position: relative;
      float: right;
      margin: -10px 0 0 0;

      div.section-text {
        font-size: 1.2em;
        padding: 35px 30px 0 30px;
      }
    }
  }

  div.section.events {
    padding-bottom: 30px;

    div.section-image {
      @include imageInCircle(280px, 0, 0, map-get($sectionColours, events), 10px);

      position: relative;
      float: right;
      margin-top: 100px;
    }

    div.section-text-circle {
      @include textInCircle(330px, 0, 0, map-get($sectionColours, events), map-get($sectionTextColours, events));

      position: relative;
      float: left;
      margin: -30px 0 0 0;

      div.section-text {
        padding: 25px 30px 0 30px;
        font-size: 1.2em;
      }
    }

    div.section-text-circle-2 {
      @include textInCircle(260px, 0, 0, map-get($sectionColours, events), map-get($sectionTextColours, events));

      position: relative;
      float: right;
      margin: -30px 0 0 0;

      div.section-text {
        padding: 30px 30px 0 30px;
      }
    }
  }

  div.section.remembrance {
    div.section-text-circle {
      @include textInCircle(280px, 0, 0, map-get($sectionColours, remembrance), map-get($sectionTextColours, remembrance));

      position: static;
      margin: 130px auto 0 auto;
      padding-bottom: 100px;

      div.section-text{
        font-size: 1.05em;
        padding: 35px 25px 0 25px;
      }
    }
  }

  div.section.contact {
    padding-bottom: 30px;

    div.section-image {
      @include imageInCircle(280px, 0, 0, map-get($sectionColours, contact));

      position: static;
      margin: 30px auto 0 auto;
    }

    div.section-text-circle {
      @include textInCircle(280px, 0, 0, map-get($sectionColours, contact), map-get($sectionTextColours, contact));

      position: static;
      margin: 130px auto 0 auto;

      div.section-text {
        padding: 65px 30px 0 30px;
        font-size: 1.2em;
      }
    }

    div.section-text-circle-1 {
      @include textInCircle(230px, 0, 0, map-get($sectionColours, contact), map-get($sectionTextColours, contact));

      position: static;
      margin: 20px auto 0 auto;

      div.section-text {
        padding: 40px 30px 0 30px;
        font-size: 1.1em;
      }
    }
  }
}
