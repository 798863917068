$sourceSans: 'source-sans-pro', sans-serif;
$tangerineFont: 'tangerine', cursive;

$white: #fff;
$black: #000;
$darkGray: #333;
$baseGray: #f0f0f0;

$yellow: #f8aa2b;
$purple: rgb(107, 107, 161);
$green: rgb(108, 173, 105);
$red: rgb(203, 74, 55);
$blue: rgb(93, 172, 205);

$smallSectionLabelDiameter: 80px;
$mediumSectionLabelDiamter: 100px;
$largeSectionLabelDiameter: 130px;
$circleSliderButtonDiameter: 45px;

$sectionColours: (
  welcome: $yellow,
  bouquets: $red,
  weddings: $yellow,
  events: $green,
  remembrance: $blue,
  contact: $red,
);

$sectionTextColours: (
  welcome: $black,
  bouquets: $white,
  weddings: $black,
  events: $white,
  remembrance: $white,
  contact: $white
);

$linkColours: (
  $yellow: $black,
  $green: $white,
  $red: $white,
  $blue: $white,
);

$logoCircleSize: (
  small: 70px,
  medium: 89px,
  large: 150px,
  xlarge: 150px,
);

@function label-sizes($size, $type) {
  @if $type == 'diameter' {
    @if $size == 'small' { @return 120px; }

    @if $size == 'medium' { @return 100px; }

    @if $size == 'large' { @return 150px; }
  }

  @if $type == 'font-size' {
    @if $size == 'small' { @return 1.5em; }

    @if $size == 'medium' { @return 1.8em; }

    @if $size == 'large' { @return 2.2em; }
  }
}

@mixin sectionBorder($colour) {
  border-top: 8px solid $colour;
}

@mixin sectionLabel($size, $colour, $textColour, $additionalDiameter:0) {
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  width: label-sizes($size, 'diameter') + $additionalDiameter;
  height: label-sizes($size, 'diameter') + $additionalDiameter;
  text-align: center;
  background-color: $colour;

  .section-label-inner {
    display: table-cell;
    height: label-sizes($size, 'diameter') + $additionalDiameter;
    width: label-sizes($size, 'diameter') + $additionalDiameter;
    vertical-align: middle;
    background-image: none;

    h2 {
      font-size: label-sizes($size, 'font-size');
      color: $textColour;
      margin: 0 1em;
      padding: 0.3em 0 0.2em 0;
      border-top: 1px solid rgba($textColour, 0.3);
      border-bottom: 1px solid rgba($textColour, 0.3);
      line-height: 1em;
    }
  }
}

@mixin textInCircle($diameter, $top, $left, $colour, $textColour) {
  display: block;
  border-radius: 50%;
  width: $diameter;
  height: $diameter;
  position: absolute;
  top: $top;
  left: $left;
  margin: 0;
  padding: 0;
  background-color: $colour;

  .section-text {
    width: $diameter;
    height: $diameter;
    margin: 0;
    padding: 75px;
    font-size: 1.05em;
    text-align: center;
    color: $textColour;

    hr {
      border: 0;
      width: 70%;
      text-align: center;
      margin: 0 auto 1em auto;
      height: 1px;
      color: rgba($textColour, 0.3);
      background-color: rgba($textColour, 0.3);
    }

    a {
      @include link($colour);
    }
  }
}

@mixin link($colour) {
  padding: 0 3px;

  &:link,
  &:visited {
    font-weight: 400;
    color: map-get($linkColours, $colour);
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

@mixin textInBox($width, $top, $left, $backgroundColour, $colour) {
  display: block;
  position: absolute;
  top: $top;
  left: $left;
  width: $width;
  background-color: $backgroundColour;
  color: $colour;
}

@mixin ring($diameter, $top, $left, $colour, $borderWidth:10px) {
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: $diameter;
  height: $diameter;
  top: $top;
  left: $left;
  border: $borderWidth solid $colour;
  background-color: transparent;
  border-radius: 50%;

  @include circleSlider($diameter - ($borderWidth * 2), $colour, 0);
}

@mixin imageInCircle($diameter, $top, $left, $colour, $borderWidth:10px) {
  display: block;
  position: absolute;
  width: $diameter;
  height: $diameter;
  top: $top;
  left: $left;
  background-color: $colour;
  border-radius: 50%;

  @include circleSlider($diameter, $colour, $borderWidth);
}

@mixin circleSlider($diameter, $colour, $borderWidth) {
  img {
    width: $diameter - (2 * $borderWidth);
    height: $diameter - (2 * $borderWidth);
    margin: $borderWidth;
  }

  ul {
    display: none;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
    }
  }

  .circle-slider-button {
    position: absolute;
    width: $circleSliderButtonDiameter;
    height: $circleSliderButtonDiameter;
    top: ($diameter/2) - ($circleSliderButtonDiameter/2);
    margin-left: -($circleSliderButtonDiameter/2);
    background-color: $colour;
    opacity: 0.8;
    border-radius: 50%;
    cursor: pointer;
    display: none;

    &.next {
      margin-left: $diameter - ($circleSliderButtonDiameter + 20);

      &::after {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        background-image: url('../images/next_prev.png');
        background-repeat: no-repeat;
        background-position: 8px -53px;
      }
    }

    &.prev {
      margin-left: 20px;

      &::after {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        background-image: url('../images/next_prev.png');
        background-repeat: no-repeat;
        background-position: 6px 7px;
      }
    }
  }
}

@mixin textInElasticBox($backgroundColour, $colour, $margin-top) {
  display: block;
  width: 90%;
  background-color: $backgroundColour;
  margin: $margin-top auto 30px auto;
  padding: 20px;
  -webkit-border-radius: 20px;
  -webkit-border-top-left-radius: 30px;
  -moz-border-radius: 20px;
  -moz-border-radius-topleft: 30px;
  border-radius: 20px;
  border-top-left-radius: 30px;

  .section-text {
    width: 100%;
    margin: 0;
    padding: 10px 5px 5px 5px;
    font-size: 1.05em;
    text-align: left;
    color: $colour;

    hr {
      border: 0;
      width: 70%;
      text-align: center;
      margin: 0 auto 1em auto;
      height: 1px;
      color: rgba($colour, 0.3);
      background-color: rgba($colour, 0.3);
    }

    a {
      @include link($backgroundColour);
    }
  }
}

@mixin menuBackgroundImage($colour, $width) {
  background-color: $colour;
  width: $width;
  border-radius: 75%/750%;
}
