@media #{$xlarge-up} {
  .row {
    width: 62.5rem;
  }

  header {
    div.menu {
      ul.main-menu {
        li {
          font-size: 1em;
          height: 55px;

          div {
            height: 55px;
            width: 135px;
          }
        }

        li:first-child {
          margin-left: 50px;
        }

        li#menu-item-welcome.active,
        li#menu-item-welcome:hover {
          @include menuBackgroundImage(map-get($sectionColours, welcome), 135px);
        }

        li#menu-item-bouquets.active,
        li#menu-item-bouquets:hover {
          @include menuBackgroundImage(map-get($sectionColours, bouquest), 135px);
        }

        li#menu-item-weddings.active,
        li#menu-item-weddings:hover {
          @include menuBackgroundImage(map-get($sectionColours, weddings), 135px);
        }

        li#menu-item-events.active,
        li#menu-item-events:hover {
          @include menuBackgroundImage(map-get($sectionColours, events), 135px);
        }

        li#menu-item-remembrance.active,
        li#menu-item-remembrance:hover {
          @include menuBackgroundImage(map-get($sectionColours, remembrance), 135px);
        }

        li#menu-item-contact.active,
        li#menu-item-contact:hover {
          @include menuBackgroundImage(map-get($sectionColours, contact), 135px);
        }
      }
    }
  }

  header.fixed {
    max-width: 62.5rem;
    margin-left: -31.25rem;

    div.header-container {
      display: none;

      div.site-name {
        display: none;
      }

      div.logo {
        display: none;
      }
    }

    div.menu {
      margin: 10px 0 20px 0;

      div.small-logo {
        width: 65px;
        height: 65px;
        margin: -10px 0 -10px 20px;
      }

      ul.main-menu {
        li {
          font-size: 0.75em;
          line-height: 1.2em;
          letter-spacing: 0.02em;
          height: 45px;
          margin-right: 2em;

          div {
            width: 100px;
            height: 45px;
          }
        }

        li#menu-item-welcome.active,
        li#menu-item-welcome:hover {
          @include menuBackgroundImage(map-get($sectionColours, welcome), 100px);
        }

        li#menu-item-bouquets.active,
        li#menu-item-bouquets:hover {
          @include menuBackgroundImage(map-get($sectionColours, bouquets), 100px);
        }

        li#menu-item-weddings.active,
        li#menu-item-weddings:hover {
          @include menuBackgroundImage(map-get($sectionColours, weddings), 100px);
        }

        li#menu-item-events.active,
        li#menu-item-events:hover {
          @include menuBackgroundImage(map-get($sectionColours, events), 100px);
        }

        li#menu-item-remembrance.active,
        li#menu-item-remembrance:hover {
          @include menuBackgroundImage(map-get($sectionColours, remembrance), 100px);
        }

        li#menu-item-contact.active,
        li#menu-item-contact:hover {
          @include menuBackgroundImage(map-get($sectionColours, contact), 100px);
        }
      }
    }
  }

  // Welcome section
  div.section.welcome {
    div.section-text-circle {
      @include textInCircle(357px, 50px, 525px, map-get($sectionColours, welcome), map-get($sectionTextColours, welcome));
    }
  }

  div.section.bouquets {
    div.section-text-circle {
      .section-text {
        font-size: 1.1em;
      }
    }

    div.section-text-circle-2 {
      @include textInCircle(150px, 450px, 520px, map-get($sectionColours, bouquets), map-get($sectionTextColours, bouquets));

      .section-text {
        padding: 35px 35px 0 35px;
        font-size: 1.2em;
      }
    }

    div.section-text-circle-3 {
      @include textInCircle(220px, 450px, 40px, map-get($sectionColours, bouquets), map-get($sectionTextColours, bouquets));

      .section-text {
        padding: 25px 20px 0 20px;
      }
    }

    div.section-image {
      @include imageInCircle(380px, 50px, 550px, map-get($sectionColours, bouquets));

      margin: 0;
    }

    div.section-image-small-2 {
      @include imageInCircle(200px, 450px, 280px, map-get($sectionColours, bouquets), 15px);
    }

    div.section-image-small-3 {
      @include imageInCircle(200px, 450px, 760px, map-get($sectionColours, bouquets), 15px);
    }
  }

  div.section.weddings {
    div.section-ring {
      @include ring(357px, 75px, 500px, map-get($sectionColours, weddings), 10px);
    }

    div.section-text-circle {
      @include textInCircle(330px, 75px, 200px, map-get($sectionColours, weddings), map-get($sectionTextColours, weddings));

      div.section-text {
        font-size: 1.1em;
      }
    }

    div.section-text-circle-1 {
      @include textInCircle(340px, 350px, 200px, map-get($sectionColours, weddings), map-get($sectionTextColours, weddings));

      div.section-text {
        font-size: 1em;
      }
    }

    div.section-text-circle-2 {
      @include textInCircle(150px, 460px, 600px, map-get($sectionColours, weddings), map-get($sectionTextColours, weddings));

      div.section-text {
        padding: 40px 30px 0 30px;
      }
    }
  }

  div.section.events {
    div.section-image {
      @include imageInCircle(400px, 75px, 180px, map-get($sectionColours, events));
    }

    div.section-text-circle {
      @include textInCircle(330px, 75px, 560px, map-get($sectionColours, events), map-get($sectionTextColours, events));
    }

    div.section-text-circle-2 {
      @include textInCircle(280px, 350px, 460px, map-get($sectionColours, events), map-get($sectionTextColours, events));
    }
  }

  div.section.remembrance {
    div.section-text-circle {
      @include textInCircle(320px, 275px, 550px, map-get($sectionColours, remembrance), map-get($sectionTextColours, remembrance));
    }
  }

  div.section.contact {
    div.section-image {
      @include imageInCircle(400px, 50px, 450px, map-get($sectionColours, contact));
    }

    div.section-ring {
      @include ring(300px, 300px, 245px, map-get($sectionColours, contact));
    }

    div.section-text-circle {
      @include textInCircle(300px, 40px, 245px, map-get($sectionColours, contact), map-get($sectionTextColours, contact));
    }

    div.section-text-circle-1 {
      @include textInCircle(200px, 350px, 600px, map-get($sectionColours, contact), map-get($sectionTextColours, contact));
    }
  }
}
